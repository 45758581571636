import './App.css';
import { useDashContext, useDashHandler } from './contexts/context';
import { useEffect, useState } from 'react';
import {Container, Row, Col} from 'reactstrap';

function App() {

  const { getFollowersByUser, getUserByTwitterUsername } = useDashHandler();
	const { followers, user } = useDashContext();

  const [followersState, setFollowersState] = useState(new Map());
  const [internalFollower, setInternalFollower] = useState('');

  useEffect(() => {
    if (user && user.status && user.data.records.length > 0){
      const followerFound = followersState.get(internalFollower);
      setFollowersState(new Map(followersState.set(internalFollower, {status: user.status})))
    }
  }, [user]);
  
  const addFollower = () => {
    setFollowersState(new Map(followersState.set(internalFollower, {})));
    getUserByTwitterUsername(internalFollower);
  };

  const handleFollowerInputChange = e => {
    setInternalFollower(e.target.value);
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          header
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            User: <input name="follower" value={internalFollower} onChange={e => handleFollowerInputChange(e)}></input>
            <button onClick={() => addFollower()}>add</button>
          </div>
          <h4>followers</h4>
          {Array.from(followersState.keys()).map((key, index) => {
            return (
              <div key={index}>{key} - {followersState.get(key).status ? 'iscritto' : 'non iscritto'}</div>
            )
          })}
        </Col>
        <Col>
          content
        </Col>
      </Row>
    </Container>
  );
}

export default App;
