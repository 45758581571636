import { createContext, memo, useCallback, useContext, useReducer } from 'react';
import { reducer,
	GET_FOLLOWERS_BY_USER, GET_USER_BY_TWITTER_USERNAME} from '../reducers/reducer';
import { getFollowersByUserService, getUserByTwitterUsernameService } from '../services/defService';

const DashContext = createContext();
const DashHandlerContext = createContext();

const initialState = { followers: undefined, user: undefined};

export const DashProvider = memo(({ children }) => {
	const [store, dispatch] = useReducer(reducer, initialState);

	const getFollowersByUser = useCallback(data => {
		getFollowersByUserService(data)
			.then(response => {
				dispatch({ type: GET_FOLLOWERS_BY_USER, loading: false, status: true, data: response.data });
			}, err => {
				dispatch({ type: GET_FOLLOWERS_BY_USER, loading: false, status: false, data: err });
			});
		dispatch({ type: GET_FOLLOWERS_BY_USER, loading: true, status: undefined, data: undefined});
	});

	const getUserByTwitterUsername = useCallback(data => {
		getUserByTwitterUsernameService(data)
			.then(response => {
				dispatch({ type: GET_USER_BY_TWITTER_USERNAME, loading: false, status: true, data: response.data });
			}, err => {
				dispatch({ type: GET_USER_BY_TWITTER_USERNAME, loading: false, status: false, data: err });
			});
		dispatch({ type: GET_USER_BY_TWITTER_USERNAME, loading: true, status: undefined, data: undefined});
	});


	return (
		<DashHandlerContext.Provider value={{
			getFollowersByUser: getFollowersByUser,
			getUserByTwitterUsername: getUserByTwitterUsername
		}} >
			<DashContext.Provider value={store}>
				{children}
			</DashContext.Provider>
		</DashHandlerContext.Provider >
	);
});

export const useDashContext = () => {
	const context = useContext(DashContext);
	if (context === undefined) {
		throw new Error('useDashContext must be used within a Provider');
	}
	return context;
};


export const useDashHandler = () => {
	const context = useContext(DashHandlerContext);
	if (context === undefined) {
		throw new Error('useDashHandler must be used within a DashProvider');
	}
	return context;
};
