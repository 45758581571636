import axios from 'axios';

export function getFollowersByUserService(id) {
	return axios({
		method: 'GET',
		url: 'https://ftdash.altervista.org/api.php/records/ft_users?join=ft_user_follower,ft_followers&filter1=id,eq,' + id
	});
}

export function getUserByTwitterUsernameService(username) {
	return axios({
		method: 'GET',
		url: 'https://ftdash.altervista.org/api.php/records/ft_wallets?filter1=twitterUserId,eq,' + username
	});
}

