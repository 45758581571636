
export const GET_FOLLOWERS_BY_USER = 'GET_FOLLOWERS_BY_USER';
export const GET_USER_BY_TWITTER_USERNAME = 'GET_USER_BY_TWITTER_USERNAME';

export function reducer(state, action) {
	switch (action.type) {
		case GET_FOLLOWERS_BY_USER:
			return { ...state, followers: {data: action.data, loading: action.loading, status: action.status}};
		case GET_USER_BY_TWITTER_USERNAME:
			return { ...state, user: {data: action.data, loading: action.loading, status: action.status}};
		default:
			return state;
	}
}
